import { Injectable } from '@angular/core';
import { Configuration } from 'msal';
import { environment } from 'src/environments/environment';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { LogLevel } from 'msal';

@Injectable({
  providedIn: 'root'
})
export class MsalConfigurationService {

  static protectedResourceMap: [string, string[]][] = [
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  ];

  constructor() { }

  static isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

  static MSALInstanceFactory() {
    return new PublicClientApplication({
      auth: {
        clientId: environment.authConfig.clientId,
        authority:
          'https://login.microsoftonline.com/' + environment.authConfig.tenant, // 'https://login.microsoftonline.com/common/',
        redirectUri: environment.authConfig.redirectUri, // 'http://localhost:4200/auth',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: this.isIE, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  static MSALInterceptorConfigFactory() {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
      'user.read',
    ]);
    environment.authConfig.addedProtectedResourceMap.map((i) => {
      protectedResourceMap.set(i.key, i.list);
    });

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }

  static MSALGuardConfigFactory() {
    return {
      interactionType: InteractionType.Redirect,
      loginFailedRoute: 'auth',
    };
  }
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}