<div class="container mr-2">
  <div class="p-5">
    <div class="flex justify-content-between grid">
      <div class="col">
        <div class="surface-400 pl-3 py-3 mb-0 text-lg">Applicant Info</div>
        <div class="surface-200">
          <div class="pl-3 p-2" *ngIf="selectedApplication">
            <h4>Applicant Name: <span class="pl-2 text-lg font-medium">{{selectedApplication.name || 'NA'}}</span></h4>
            <h4>App ID: <span class="pl-2 text-lg font-medium">{{selectedApplication.appID || 'NA'}}</span></h4>
            <h4>Control No: <span class="pl-2 text-lg font-medium">{{selectedApplication.controlNo || 'NA'}}</span></h4>
            <h4> DOB: <span class="pl-2 text-lg font-medium">{{selectedApplication.dob || 'NA'}}</span></h4>
            <h4> Address: <span class="pl-2 text-lg font-medium">{{selectedApplication.address || 'NA'}}</span></h4>
            <h4> Home Phone: <span class="pl-2 text-lg font-medium">{{selectedApplication.homePhone || 'NA'}}</span></h4>
            <h4> Work Phone: <span class="pl-2 text-lg font-medium">{{selectedApplication.workPhone|| 'NA'}}</span></h4>
            <h4> SSN: <span class="pl-2 text-lg font-medium">{{selectedApplication.ssn || 'NA'}}</span></h4>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="surface-400 pl-3 py-3 mb-0 text-lg">Provider Info</div>
        <div class="surface-200">
          <div class="pl-3 p-2" *ngIf="selectedApplication && selectedApplication.apsProvider">
            <h4>Provider Name: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.name || 'NA'}}</span></h4>
            <h4>Facility Name: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.facilityName || 'NA'}}</span></h4>
            <h4> Address: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.address || 'NA'}}</span></h4>
            <h4> Home Phone: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.homePhone || 'NA'}}</span></h4>
            <h4> Fax: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.fax|| 'NA'}}</span></h4>
            <h4> Tax ID: <span class="pl-2 text-lg font-medium">{{selectedApplication.apsProvider.taxID || 'NA'}}</span></h4>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5"> 
      <div class="surface-400">
        <div class="grid">
          <div class="col font-semibold ml-2 mb-2">Submitted Date</div>
          <div class="col font-semibold ml-2 mb-2">Comment</div>
        </div>
      </div>
      <div class="surface-200">
        <div class="grid" *ngIf="selectedApplication">
          <div class="col ml-2 mb-2">{{selectedApplication.contacts[0].contactTime}}</div>
          <div class="col ml-2 mb-2">{{selectedApplication.contacts[0].contactDescription}}</div>
        </div>
      </div>
    </div>

  </div>
  <app-ushg-dialog-footer [mode]="modeType"  [buttonsList]="buttonsList"></app-ushg-dialog-footer> 
</div>