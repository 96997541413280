import { ColumnType } from "src/app/aps/models/enums";

export const TrackApsHeaders = (This) => {
    return [
      {
        label: 'Application ID',
        field: 'appID',
      },
      { label: 'Control Number', field: 'controlNo' },
      { label: 'Primary Name', field: 'name' },
      { label: 'Request Date', field: 'requestDate' },
      { label: 'Address', field: 'address' },
      { label: 'Home', field: 'homePhone' },
      { label: 'Work', field: 'workPhone' },
      {
        label: 'View',
        field: 'View',
        type: ColumnType.View,
        onChange: (applicationData) => {
          return This.viewActionEventHandler(applicationData);
        },
      },
    ];
  };