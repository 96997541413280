import { state } from '@angular/animations';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { Observable, Subscriber, of } from 'rxjs';
import { delay, switchMap, catchError, map } from 'rxjs/operators';
import {
  RequestOrderPdf,
  ResetRequestOrderPdf,
  ResetSpinner,
  SetSpinner,
  UpdateOrder,
} from 'src/app/store/dispatchers';
import { environment } from 'src/environments/environment';
import { PDF_ATTRIBUTE_KEYS } from '../configs/pdf-attribute-keys';
import { CheckRequest } from '../models/application-model';
import {
  COMPANY,
  SECURE_EMAIL_TEMPLATES,
  TemplateTitle,
} from '../models/enums';
import { QueryResponse } from '../models/query-reponse';
import { ProcessStatusPayload, ProviderSearchModel } from '../models/ui-models';
import { Query } from '../queries/query-model';
import {
  applicationDetails,
  assignApplicationToApsUser,
  auditLogAndNotes,
  createOrder,
  filterAssignedApplications,
  followUp,
  getproviderDetails,
  listOfApplicationsBasedOnSearch,
  listOfApplicationsForLoggedInApsUser,
  routeTo,
  savePdfQuery,
  updateProviderCommunicationPreferences,
  downloadDocuments,
  insertAuditRecord,
  updateOrder,
  getUsersWorkload,
  saveReminderDate,
  updateOrderProcessStatus,
  applicationDetailsByApplicationId,
  lockApplication,
  upsertOrderProperties,
  toggleAuditRecordPin,
  updateProviderPhoneExtension,
  applicationStatusDetails,
  listOfApplicationsForFollowUpQueue,
} from '../queries/query-objects';
import { userProfile } from './jsondata';
import { UtilityService } from './utility-service';
import { ShareFileClient } from "@azure/storage-file-share";
import { FileService } from 'src/app/shared/services/file-service';
import { FileUpload } from 'primeng/fileupload';


@Injectable({
  providedIn: 'root',
})
export class ApsApiService {
  apiNbcUrl = environment.serverUrls.nbcApiURl;
  MAX_CHUNK_SIZE = 4194304; // 4 MiB

  constructor(
    private http: HttpClient,
    private utilService: UtilityService,
    private apollo: Apollo,
    private store: Store,
    private fileService: FileService,
  ) { }

  getUserProfile() {
    return this.getDummyProfile();
  }

  public getDummyProfile() {
    return new Observable<any>((o) => {
      const appt = userProfile;
      o.next(appt);
    });
  }

  getListofApplicants(): Observable<QueryResponse> {
    return this.apollo.query({
      query: Query.LIST_OF_APPLICANTS,
    });
  }

  getUsersWorkload(): Observable<QueryResponse> {
    return this.apollo.query({
      query: getUsersWorkload,
    });
  }

  getListofApplicationsBasedOnSearch(
    searchCriteria?
  ): Observable<QueryResponse> {
    return this.apollo.query({
      query: listOfApplicationsBasedOnSearch(searchCriteria),
    });
  }

  getApplicationDetails(appId?): Observable<QueryResponse> {
    return this.apollo.query({
      query: applicationDetails(appId),
    });
  }

  getApplicationStatusDetails(appId?): Observable<QueryResponse> {
    return this.apollo.query({
      query: applicationStatusDetails(appId),
    });
  }

  getFilteredAssignApplications(filters): Observable<QueryResponse> {
    return this.apollo.query({
      query: filterAssignedApplications(filters),
    });
  }

  getFilteredAssignedFollowupApplications(filters): Observable<QueryResponse> {
    return this.apollo.query({
      query: listOfApplicationsForFollowUpQueue(filters),
    });
  }

  getAuditLogAndNotes(appId?): Observable<QueryResponse> {
    console.log(appId);
    return this.apollo.query({
      query: auditLogAndNotes(appId),
    });
  }

  saveReminderDate(reminderDate): Observable<QueryResponse> {
    return this.apollo.query({
      query: saveReminderDate,
      variables: {
        reminderDate,
      },
    });
  }

  createOrder(request) {
    return this.apollo.mutate({
      mutation: createOrder(request),
    });
  }

  newOrderLogEntry(data) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const { name } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    const { applicationId } = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    if (currentOrderData && currentOrderData !== null) {
      data.applicationTrackerId = data.applicationTrackerId
        ? data.applicationTrackerId
        : currentOrderData?.applicationTrackerId;
    }
    data.applicationId = applicationId;
    data.loggedBy = name;

    return this.apollo.mutate({
      mutation: insertAuditRecord,
      variables: {
        auditLogRecord: data,
      },
    });
  }

  togglOrderLogEntryPin(data) {
    return this.apollo.mutate({
      mutation: toggleAuditRecordPin,
      variables: {
        request: JSON.stringify(data),
      },
    });
  }

  assignApplicationToApsUser(application) {
    return this.apollo.mutate({
      mutation: assignApplicationToApsUser(application),
    });
  }

  updateProviderPhoneExtension(providerPhoneExtension) {
    return this.apollo.mutate({
      mutation: updateProviderPhoneExtension,
      variables: {
        providerPhoneExtension: JSON.stringify(providerPhoneExtension),
      },
    });
  }

  updateProviderDetailPreferences(provider, preferences, application) {
    const profile = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    const providerPayload = {
      city: provider.city,
      id: provider.id,
      fullname: provider.firstName,
      distance: 0.44,
      specialties: provider.specialties,
      phone: provider.phone,
      state: provider.state,
      streetAddress: provider.streetAddress,
      zipCode: provider.zipCode,
      lastVisited: null,
      firstName: provider.name,
      lastName: provider.lastName,

      communicationPreferences: {
        communicationMode: preferences.communicationMode,
        electronicSignature: preferences.electronicSignature,
        creditCardPayments: preferences.creditCardPayments,
        specialRequestLetter: preferences.specialRequestLetter,
        trunAroungTime: preferences.trunAroungTime,
        lastModifiedDate: new Date().toISOString(),
        lastModifiedBy: profile.name,
      },
    };
    return this.apollo.mutate({
      mutation: updateProviderCommunicationPreferences,
      variables: {
        provider: providerPayload,
      },
    });
  }

  getProviderDetailPreferences(id): Observable<QueryResponse> {
    return this.apollo.query({
      query: getproviderDetails(id),
    });
  }

  getApsUsersList(): Observable<QueryResponse> {
    return this.apollo.query({
      query: Query.LIST_OF_APS_USERS,
    });
  }

  getWorkQueueList() {
    return this.apollo.query({
      query: Query.LIST_OF_WORK_QUEUE_APPLICATIONS,
      notifyOnNetworkStatusChange: true,
    });
  }

  getListOfAssignedApplicationsToCurrentApsMember(
    pageNo,
    queryId?,
    after?
  ): Observable<QueryResponse> {
    const { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    return this.apollo.query({
      query: listOfApplicationsForLoggedInApsUser(
        domainUserName,
        queryId,
        pageNo,
        after
      ),
    });
  }

  getRequestOrderPdf(pdfTitle, applicantDetails) {
    const providerPreferences = this.store.selectSnapshot(
      (state) => state.data.providerPreferences
    );
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    applicantDetails = JSON.parse(JSON.stringify(applicantDetails));
    let attributeKeys = PDF_ATTRIBUTE_KEYS[pdfTitle];
    if (pdfTitle === TemplateTitle.AUTHORIZATION) {
      attributeKeys =
        providerPreferences.electronicSignature ||
          providerPreferences.electronicSignature === 'N/A'
          ? attributeKeys
          : attributeKeys.filter(
            (key) =>
              key !== 'SignatureDate' &&
              key !== 'PatientSignature' &&
              key !== 'AuthDisclAppSign'
          );
      applicantDetails.reasonForDisclosure = 'Yes';
      applicantDetails.Other = 'Yes';
    }
    let provider
    if (applicantDetails.orderDetails.APS) {
      provider = applicantDetails.orderDetails.APS.providerOverride ? applicantDetails.orderDetails.APS.providerOverride : applicantDetails.orderDetails.APS.provider;
    }
    if (
      pdfTitle === TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE ||
      pdfTitle === TemplateTitle.AUTHORIZATION
    ) {
      if (provider) {
        provider.fullname = provider
          .facilityName
          ? `${provider.facilityName
          } Attn: ${this.utilService.transformFirstSpaceLastName(
            provider.fullname
          )}`
          : provider.fullname;
      }
    }
    if (provider && provider.fullname) {
      provider.providerFullName =
        pdfTitle === TemplateTitle.AUTHORIZATION
          ? provider.fullname
          : this.utilService.transformFirstSpaceLastName(
            provider.fullname
          );
    }
    applicantDetails.CompanyName = COMPANY[applicantDetails.company];
    applicantDetails.orderApplicant.applicantState = applicantDetails?.orderApplicant.contactState
    const template = {
      ApplicantName: applicantDetails.primaryContactName,
      AppId: '' + applicantDetails.applicationId,
      OrderId: applicantDetails.orderTrackerId,
      Company: applicantDetails.company,
      TemplateName: `${applicantDetails.company}_${pdfTitle}.pdf`,
      Attributes: this.dictionaryValues(attributeKeys, {
        ...applicantDetails,
        ...applicantDetails?.orderApplicant,
        ...provider,
        ...applicantDetails?.contact,
      }),
    };
    this.store.dispatch(new SetSpinner());
    this.http
      .post(environment.serverUrls.nbcApiURl + 'pdf/v1/template', template)
      .subscribe(async (resp) => {
        this.store.dispatch(new ResetSpinner());
        const pdfBlobUrl = await this.convertBinaryDatatoBlob(resp);
        const versionNumber = Math.floor(Math.random() * 100);
        let pdf = [
          {
            label: currentOrderData.isEditOrder
              ? `${pdfTitle}${versionNumber}.pdf`
              : `${pdfTitle}.pdf`,
            blob: pdfBlobUrl,
            applicationId: applicantDetails.applicationId,
            orderId: applicantDetails.orderTrackerId,
            byteStream: resp,
            documentType: pdfTitle,
            isNewlyAdded: currentOrderData.isEditOrder ? true : false,
            updatedDate: new Date().toISOString(),
          },
        ];
        this.store.dispatch(new RequestOrderPdf(pdf));
      });
  }

  getDocsListForApplication(appId) {
    return this.http.get(
      `${environment.serverUrls.vCallExportUrl}/${appId}/export`
    );
  }

  searchProviders(payload: ProviderSearchModel): any {
    return this.http.post(`${environment.serverUrls.providerLookup}`, payload);
  }

  private dictionaryValues(keys, data) {
    return keys.reduce((acc = [], curr) => {
      const obj = {
        Name: curr,
        Value: data[curr] ? '' + data[curr] : '',
      };
      const res = [...acc, { ...obj }];
      return res;
    }, []);
  }

  public convertBinaryDatatoBlob(binaryData) {
    return new Promise((resolve, reject) => {
      var blob = new Blob([this.stringToUint8Array(binaryData)], {
        type: 'application/pdf',
      });
      let url = URL.createObjectURL(blob);
      if (url) {
        resolve(url);
      } else {
        reject("PDF Couldn't Load");
      }
    });
  }

  private stringToUint8Array(raw) {
    raw = atob(raw);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  }

  savePdf(pdf, documents) {
    this.savePdfToTheApplication(pdf, documents);
  }

  savePdfToTheApplication(pdf, documents): Observable<QueryResponse> {
    return this.apollo.mutate({
      mutation: savePdfQuery(pdf, documents),
    });
  }

  savePdfToTheApplicationSas(pdfDetails, documents, file, fileUpload: FileUpload): Observable<QueryResponse> {
    let payLoad = {
      appId: `${pdfDetails.applicationId}`,
      orderId: `${pdfDetails.orderTrackerId}`,
      clientNumber: `${pdfDetails.clientNumber}`,
      documents: [{
        name: `${documents[0].name}`,
        attributes: `${documents[0].attributes}`,
        etag: '',
        content: '',
        contentLength: file.size,
        documentType: `${documents[0].documentType}`,
        updatedDate: `${documents[0].updatedDate}`
      }]
    };

    let that = this;
    return that.http.post(environment.serverUrls.nbcApiURl + 'api/documents/v1/file/getsasinfo', payLoad).pipe(

      switchMap((res: any) => {
        payLoad.documents[0].name = res[0].name;
        payLoad.documents[0].content = res[0].content;
        return that.uploadFileToAzureFileShare(res[0].sasUrl, file, payLoad, fileUpload)
      }),
      switchMap((azureResponse: any) => {
        let data = { uploadFiles: { ...azureResponse.payLoad } };
        data.uploadFiles.documents[0].etag = azureResponse.etag;
        let result: QueryResponse = { data };
        return of(result);
      })
    );
  }


  async uploadFile(observer: Subscriber<any>, sasUrl: string, file: File, payLoad: any, fileUpload: FileUpload) {
    try {
      const fileClient = new ShareFileClient(sasUrl);
      const totalSize = file.size;

      if (totalSize <= this.MAX_CHUNK_SIZE) {
        // Single upload for smaller files
        const fileReader = new FileReader();
        fileReader.onload = async (ev) => {
          await fileClient.uploadRange(ev.target.result as ArrayBuffer, 0, totalSize);
          const properties = await fileClient.getProperties();
          const existingMetadata = properties.metadata;
          await fileClient.setMetadata({
            ...existingMetadata,
            uploadComplete: 'true'
          });
          fileUpload.onProgress.emit(100);
          observer.next({ etag: properties.etag, payLoad: payLoad });
          observer.complete();
        };
        fileReader.onerror = (error) => observer.error(new Error(`File read failed: ${error}`));
        fileReader.readAsArrayBuffer(file);
      } else {
        // Chunked upload for larger files
        let uploadedBytes = 0;
        while (uploadedBytes < totalSize) {
          const remainingSize = totalSize - uploadedBytes;
          const currentChunkSize = Math.min(this.MAX_CHUNK_SIZE, remainingSize);
          const slice = file.slice(uploadedBytes, uploadedBytes + currentChunkSize);
          const buffer = await slice.arrayBuffer();

          await fileClient.uploadRange(buffer, uploadedBytes, currentChunkSize);
          fileUpload.onProgress.emit(Math.ceil((uploadedBytes / totalSize) * 100));
          uploadedBytes += currentChunkSize;
        }

        // Fetch the latest file properties to get the eTag
        const properties = await fileClient.getProperties();
        const existingMetadata = properties.metadata;
        await fileClient.setMetadata({
          ...existingMetadata,
          uploadComplete: 'true'
        });
        fileUpload.onProgress.emit(100);
        observer.next({ etag: properties.etag, payLoad: payLoad });
        observer.complete();
      }
    } catch (error) {
      observer.error(new Error(`Upload to Azure failed: ${error.message || error}`));
    }
  }


  uploadFileToAzureFileShare(sasUrl: string, file: File, payLoad: any, fileUpload: FileUpload): Observable<any> {
    return new Observable(observer => {
      this.uploadFile(observer, sasUrl, file, payLoad, fileUpload);
    });
  }

  routeTo(request) {
    return this.apollo.mutate({
      mutation: routeTo,
      variables: {
        request: JSON.stringify(request),
      },
    });
  }



  addFollowUp(followupPaylaod) {
    return this.apollo.mutate({
      mutation: followUp,
      variables: {
        followUp: followupPaylaod,
      },
    });
  }

  sendSecureEmailRequest(payload) {
    var secureUrl;
    switch (payload.template) {
      case SECURE_EMAIL_TEMPLATES.SIGNED_AUTHORIZATION:
        secureUrl = 'signedauthorization';
        break;
      case SECURE_EMAIL_TEMPLATES.LABORATORY:
        secureUrl = 'labrequest';
        break;
      case SECURE_EMAIL_TEMPLATES.UNDERWRITING_NEEDS_MORE_INFORMATION:
        secureUrl = 'uwneedmoreinfo';
        break;
    }

    return this.http.post(
      `${environment.serverUrls.secureEmail}/${secureUrl}`,
      payload,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'text',
      }
    );
  }

  getListOfAssignedApplicationsForFollowupQueue(query): Observable<QueryResponse> {
    return this.apollo.query({
      query: listOfApplicationsForFollowUpQueue(query),
    });
  }

  downloadFileFromAzureFileShare(sasUrl: string): any {
    return this.http.get(sasUrl, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer: ArrayBuffer) => {
        const uint8Array = new Uint8Array(arrayBuffer);
        const base64String = this.fileService.uint8ArrayToBase64(uint8Array);
        return { base64String };
      })
    );
  }

  downloadIndividualDocuments(payload: any): Observable<QueryResponse> {
    let that = this;
    return that.http.post(environment.serverUrls.nbcApiURl + 'api/documents/v1/file/getsasinfo', payload).pipe(

      switchMap((res: any) => {
        return that.downloadFileFromAzureFileShare(res[0].sasUrl)
      }),
      switchMap((azureResponse: any) => {
        let data = { downloadOrderFiles: { ...payload } };
        data.downloadOrderFiles.documents[0].content = azureResponse.base64String;
        let result: QueryResponse = { data };
        return of(result);
      })
    );
  }

  downloadIndividualDocuments_old(payload): Observable<QueryResponse> {
    debugger;
    return this.apollo.query({
      query: downloadDocuments,
      variables: {
        orderDocumentDetails: JSON.stringify(payload),
      },
    });
  }

  insertCheckRequest(value): Observable<QueryResponse> {
    return this.http.post(
      environment.serverUrls.nbcApiURl + 'check/v1/',
      value
    );
  }

  insertAuditRecord(data) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const { name } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    const { applicationId } = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    if (currentOrderData && currentOrderData !== null) {
      data.applicationTrackerId = data.applicationTrackerId
        ? data.applicationTrackerId
        : currentOrderData?.applicationTrackerId;
    }
    data.applicationId = applicationId;
    data.loggedBy = name;
    console.log('audit data---', data)
    return this.apollo.mutate({
      mutation: insertAuditRecord,
      variables: {
        auditLogRecord: data,
      },
    });
  }

  getUnderWriters() {
    return this.apollo
      .query({
        query: Query.GET_LIST_OF_UNDERWRITERS,
      })
      .toPromise();
  }

  public getPaymentCheck(applicationId): Observable<CheckRequest[]> {
    return this.http.get<CheckRequest[]>(
      `${this.apiNbcUrl}check/v1/${applicationId}`
    );
  }

  saveGeneratedPdfToApplication() {
    let documents = this.store.selectSnapshot(
      (state) => state.data.requestOrderPdf
    );
    let selectedApplicant = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let obj = `{"uploadedOn":"${new Date().toISOString()}", "appId":"${selectedApplicant?.applicationId
      }"}`;
    let profile = this.store.selectSnapshot((state) => state.data.userProfile);
    const documentPromises = [];
    documents.map((document) => {
      if (!document.name) {
        document.name = document.label;
        document.content = document.byteStream;
        document.attributes = JSON.stringify(obj);
        document.createdDate = new Date().toISOString();
        document.createdBy = profile.name;
        documentPromises.push(this.savePdfToTheApplication(selectedApplicant, [document]).toPromise());
      }
      return document;
    });
    this.store.dispatch(new SetSpinner());
    return Promise.all(documentPromises).then((documentsResp) => {
      this.store.dispatch(new ResetSpinner());
      documentsResp.forEach((documentResponse, index) => {
        const documentsData = documentResponse.data.uploadFiles.documents[0];
        const document = documents[index];
        document.content = documentsData.content;
        document.name = documentsData.name;
        document.url = documentsData.content;
        document.eTag = documentsData.eTag;
      });
      this.store.dispatch(new ResetRequestOrderPdf());
      this.store.dispatch(new RequestOrderPdf(documents));
    });
  }

  updateOrder(request, orderAction) {
    return this.apollo.mutate({
      mutation: updateOrder,
      variables: {
        request: JSON.stringify(request),
        action: orderAction,
      },
    });
  }

  searchApplications(payload): any {
    return this.http.post(
      `${environment.serverUrls.searchApplication}`,
      payload
    );
  }

  trackApplications(payload): Observable<any> {
    return this.http.post(
      `${environment.serverUrls.nbcLegacy}/api/v1/aps/search`,
      payload
    );
  }

  trackApplicationView(requestId: number): Observable<any> {
    return this.http.get(
      `${environment.serverUrls.nbcLegacy}/api/v1/aps/request/${requestId}/details`
    );
  }

  importApplication(applicationId: string, name: string): Observable<any> {
    const url = `${environment.serverUrls.nbcLegacy}/application/v1/import/application/${applicationId}/${name}`;
    return this.http.post(url, {});
  }

  updateOrderStatusFlag(request: ProcessStatusPayload) {
    return this.apollo.mutate({
      mutation: updateOrderProcessStatus,
      variables: {
        request: JSON.stringify(request),
      },
    });
  }

  getApplicationDetail(applicationId?): Observable<QueryResponse> {
    return this.apollo.query({
      query: applicationDetailsByApplicationId(applicationId),
    });
  }

  upsertOrderProperties(orderProperties) {
    return this.apollo.mutate({
      mutation: upsertOrderProperties,
      variables: {
        request: JSON.stringify(orderProperties),
      },
    });
  }

  getBrcodes(applicationId: number) {
    return this.http.get(
      `${environment.serverUrls.nbcLegacy}/efulfillment/v1/Application/${applicationId}/Brcode`,
    );
  }

  saveAuthorizationFile(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: headers,
      body: JSON.stringify(payload)
    };
    return this.http.request(
      'POST',
      `${environment.serverUrls.nbcLegacy}/api/provider/v1/UpsertProvider`, options

    );
  }

  searchAuthorizationFile(searchData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      body: JSON.stringify(searchData),
      headers: headers,
    };
    return this.http.request(
      'POST',
      `${environment.serverUrls.nbcLegacy}/api/provider/v1/GetProvider`, options
    );
  }

  downloadProviderDocument(sasUrlpayload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      body: JSON.stringify(sasUrlpayload),
      headers: headers,
    };
    let that = this;
    return that.http.request(
      'POST',
      `${environment.serverUrls.nbcLegacy}/api/provider/v1/getSasInfo`, options
    ).pipe(
      switchMap((res: any) => {
        return that.downloadFileFromAzureFileShare(res['sasUrl'])
      }),
      switchMap((azureResponse: any) => {
        const result = { pdfTitle: sasUrlpayload.documents['name'], byteStream: azureResponse.base64String }
        return of(result);
      })
    );
  }
}

