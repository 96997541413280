import { Validators } from "@angular/forms";

export const demographicFormConfig = 
[
    {
        formControlName: "policyNumber",
        label: "Select Policy",
        value: 'policyNumber',
        detailsDirection:"column",
        class:"w-full pl-0",
        labelClass:"col-2",
        controlValue: 'policyNumber',
        formControlType: 'brCodeDropDown',
        validationMessage: " * BR code does not exist for this policy number.",
        options: [],
        type:'brCode'
    },
   {
       formControlName: "firstName",
       label: "FirstName",
       value: 'firstName',
       controlValue:"firstName",
       validationMessage : "Please enter a valid First name",
       formControlType: 'text',
       validators: [Validators.pattern("^[a-zA-Z\- ]+$"),Validators.required]
   },
   {
    formControlName: "middleName",
    label: "MiddleName",
    value: 'middleName',
    formControlType: 'text',
    validationMessage : "Please enter a valid Middle name",
    validators: [Validators.pattern("^[a-zA-Z\- ]+$")]
    },
    {
        formControlName: "lastName",
        label: "LastName",
        value: 'lastName',
        formControlType: 'text',
        validationMessage : "Please enter a valid Last name",
        validators: [Validators.pattern("^[a-zA-Z\- ]+$"),Validators.required]
    },
    {
        formControlName: "birthDate",
        label: "DOB",
        value: 'birthDate',
        formControlType: 'datepicker',
        validationMessage : "Please select a valid Birth date",
        type:'date',
        validators: [Validators.required]
 
    },
    {
        formControlName: "gender",
        label: "Gender",
        value: 'gender',
        validationMessage : "Please select a Gender",
        formControlType: 'select',
        customClass: 'col-6',
        validators: [Validators.required],
        options: [
            {
                label: 'Select an Option'
            },
            {
                label: 'M', value: 'M',
            },
            {
                label: 'F', value: 'F',
            }
        ]
    },
    {
        formControlName: "ssn",
        label: "SSN",
        value: 'ssn',
        formControlType: 'ssn',
        validationMessage : "Please enter a valid SSN number",
        mask: true
    }, 
   {
       formControlName: "birthCountry",
       label: "Location",
       value: 'birthCountry',
       formControlType: 'text',
       validationMessage : "Please enter a valid Birth country",
       validators : [Validators.pattern("^[a-zA-Z]+$"),Validators.required],
   },
   {
    formControlName: "contactState",
    label: "State",
    value: 'contactState',
    formControlType: 'text',
    validationMessage : "Please enter a valid State name",
    validators : [Validators.maxLength(2), Validators.pattern('^[a-zA-Z]+$'),Validators.required]
    },   
    {
    formControlName: "applicantstreetAddress",
    label: "Street",
    value: 'applicantstreetAddress',
    validationMessage: "Please enter a valid Street address",
    formControlType: 'text',
    validators: [Validators.required] 
    }, 
   {
       formControlName: "applicantHomePhone",
       label: "Home",
       value: 'applicantHomePhone',
       validationMessage : "Please enter a valid Phone number",
       formControlType: 'phone',
       validators: [Validators.required]
   },
   {
       formControlName: "applicantmobilePhone",
       label: "Mobile",
       value: 'applicantmobilePhone',
       validationMessage : "Please enter a valid Phone number",
       formControlType: 'phone',
   },
   {
       formControlName: "applicantCity",
       label: "City",
       value: 'applicantCity',
       formControlType: 'text',
       validationMessage : "Please enter a valid City name",
       validators: [Validators.required]

   },
   {
       formControlName: "applicantzipCode",
       label: "Zip",
       value: 'applicantzipCode',
       validationMessage : "Please enter a valid Zip code",
       formControlType: 'text',
       validators : [Validators.pattern('^([0-9]{5}(-[0-9]{4})?)?$'),Validators.required]
   },
   {
    formControlName: "applicantEmail",
    label: "Email",
    value: 'applicantEmail',
    validationMessage : "Please enter valid Email address",
    formControlType: 'text',
    validators: [Validators.pattern("^[a-zA-Z0-9'._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,8}$"),Validators.required]
},
]
