import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { ApsWrapperComponent } from './components/core/aps-wrapper/aps-wrapper.component';
import { CreateOrderComponent } from './components/core/create-order-components/create-order-wrapper/create-order.component';
import { DemographicDetailsComponent } from '../shared/demographic-details/demographic-details.component';
import { GeneratePdfComponent } from '../shared/generate-pdf/generate-pdf.component';
import { MyQueueComponent } from './components/core/my-queue/my-queue.component';
import { NewRequestQueueComponent } from './components/core/new-request-queue/new-request-queue.component';
import { WorkQueueComponent } from './components/core/work-queue/work-queue.component';
import { RequestLettersComponent } from './components/core/create-order-components/create-lab-order-ae-components/request-letters/request-letters.component';
import { SendEmailComponent } from '../shared/send-email/send-email.component';
import { Resolvers } from './resolvers/resolvers';
import { ApsService } from './services/aps-service';
import { CreateApsOrderDemographicComponent } from './components/core/create-order-components/create-aps-order-components/aps-order-demographic-details/create-aps-order-demographic.component';
import { LabOrderAeDemographicComponent } from './components/core/create-order-components/create-lab-order-ae-components/lab-order-ae-demographic/lab-order-ae-demographic.component';
import { FollowupQueueComponent } from './components/core/followup-queue/followup-queue.component';
import { AuthorizationGuard } from '../core/guards/authorization.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { PermissionGuard } from '../core/guards/permission.guard';
import { Permission } from './models/permission';
import { SearchApplicationComponent } from './components/core/search-application/search-application.component';
import { UshgApplicationDetailComponent } from './components/core/ushg-application-detail/ushg-application-detail.component';
import { AuthorizationFormsContainerComponent } from './components/core/authorization/authorization-forms-container/authorization-forms-container.component';
import { TrackApsComponent } from './components/core/track-aps/track-aps.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'aps/new-request-queue',
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: '',
    component: ApsWrapperComponent,
    children: [
      {
        path: 'new-request-queue',
        component: NewRequestQueueComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
        data: {
          requiredPermission: Permission.CanAccessNewRequestQueue,
        },
        resolve: {
          data: 'NewRequestQueueResolver',
        },
      },
      {
        path: 'my-queue',
        component: MyQueueComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
        data: {
          requiredPermission: Permission.CanAccessMyQueue,
        },
        resolve: {
          data: 'MyQueueResolver',
        },
      },
      {
        path: 'work-queue',
        component: WorkQueueComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
        data: {
          requiredPermission: Permission.CanAccessWorkQueue,
        },
        resolve: {
          data: 'WorkQueueResolver',
        },
      },
      {
        path: 'followup-queue',
        component: FollowupQueueComponent,
        resolve: {
          data: 'FollowupQueueResolver',
        },
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
        data: {
          requiredPermission: Permission.CanAccessFollowUp,
        },
      },
      {
        path: 'search-application',
        component: SearchApplicationComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
      },
      {
        path: 'application-details',
        component: UshgApplicationDetailComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
      },
      {
        path: 'authorization-forms',
        component: AuthorizationFormsContainerComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
      },
      {
        path: 'track-aps',
        component: TrackApsComponent,
        canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard]
      },
    ],
  },
  {
    path: 'create-order',
    pathMatch: 'prefix',
    component: CreateOrderComponent,
    // canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
    // data: {
    //     requiredPermission: Permission.CanAccessCreateOrder,
    // },
    children: [
      {
        path: 'demographic',
        component: DemographicDetailsComponent,
      },
      {
        path: 'request-letters',
        component: RequestLettersComponent,
        children: [],
      },
      {
        path: 'generate-pdf',
        component: GeneratePdfComponent,
      },
      {
        path: 'send',
        component: SendEmailComponent,
      },

      {
        path: 'aps-demographic',
        component: CreateApsOrderDemographicComponent,
      },
      {
        path: 'laborder-ae-demographic',
        component: LabOrderAeDemographicComponent,
      },
      {
        path: 'LAB_AE_HTWTBP',
        component: LabOrderAeDemographicComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'NewRequestQueueResolver',
      useFactory: (ApsService, Store, Actions) =>
        new Resolvers(ApsService, Store, Actions).newRequestQueueResolver(),
      deps: [ApsService, Store, Actions],
    },
    {
      provide: 'MyQueueResolver',
      useFactory: (ApsService, Store, Actions) =>
        new Resolvers(ApsService, Store, Actions).myQueueResolver(),
      deps: [ApsService, Store, Actions],
    },
    {
      provide: 'WorkQueueResolver',
      useFactory: (ApsService, Store, Actions) =>
        new Resolvers(ApsService, Store, Actions).workQueueResolver(),
      deps: [ApsService, Store, Actions],
    },
    {
      provide: 'FollowupQueueResolver',
      useFactory: (ApsService, Store, Actions) =>
        new Resolvers(ApsService, Store, Actions).followupQueueResolver(),
      deps: [ApsService, Store, Actions],
    },
  ],
})
export class ApsRoutingModule { }
