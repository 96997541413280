<div class="p-4 bg-white shadow-3 mb-3 mt-3">
  <form [formGroup]="trackApsForm" id="trackApsForm" (submit)="searchApplications()">
    <div class="flex justify-content-between">
      <div class="mr-3 search-col">
        <label for="applicationId">{{TrackApsFormLabel.applicationId}}</label>
        <input id="float-input" type="text" (keyup.enter)="trackApsForm.invalid===false && searchApplications()"
          pInputText name="applicationId" id="applicationId" formControlName="applicationId" autocomplete="off"
          maxlength="9" numbersOnly class="w-full" />
      </div>
      <div class="mr-3 search-col">
        <label for="name">{{TrackApsFormLabel.primaryName}}</label>
        <input id="float-input" type="text" (keyup.enter)="trackApsForm.invalid===false && searchApplications()"
          pInputText formControlName="name" class="w-full" autocomplete="off" />
      </div>
      <div class="mr-3 search-col">
        <label for="controlNumber">{{TrackApsFormLabel.controlNumber}}</label>
        <input id="float-input" type="text" (keyup.enter)="trackApsForm.invalid===false && searchApplications()"
          pInputText formControlName="controlNumber" class="w-full" autocomplete="off" />
      </div>
      <div class="mr-3 search-col">
        <label for="icon" class="block">{{TrackApsFormLabel.fromDate}}</label>
        <p-calendar [showIcon]="true" inputId="icon" [maxDate]="today" formControlName="fromDt" class="w-full"
          [readonlyInput]="true">
        </p-calendar>
      </div>
      <div class="mr-3 search-col">
        <label for="icon" class="block">{{TrackApsFormLabel.toDate}}</label>
        <p-calendar [showIcon]="true" inputId="icon" [maxDate]="today" formControlName="toDt" class="w-full"
          [readonlyInput]="true">
        </p-calendar>
      </div>
      <div class="mr-3 search-col">
        <label for="status">{{TrackApsFormLabel.status}}</label>
        <p-dropdown [options]="StatusFilterTrackAPS" formControlName="statusID" class="w-full" optionLabel="name"
          class="w-full" optionValue='value'></p-dropdown>
      </div>

      <div class="search-col-submit">
        <span class="mr-2"><ushg-button [label]="'Reset'" [type]="'outline'" (click)="reset()"></ushg-button></span>
        <ushg-button [label]="'Search'" [type]="'primary'" (click)="searchApplications()"
          [disable]="trackApsForm.invalid">
        </ushg-button>
      </div>
    </div>
  </form>
</div>

<div class="col-12 shadow-3 p-0">
  <ushg-table [headerList]="headersList" [rows]="100" [rowData]="trackApsApplications | paginate 
  : {
      itemsPerPage: 10,
      currentPage: pageNumber,
      totalItems: totalItems
    }" *ngIf="trackApsApplications.length">
  </ushg-table>
  <div class="text-center pt-1 pb-2">
    <div *ngIf="trackApsApplications.length === 0" class="my-3">
      <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="ml-3">Total : <b>{{trackApsApplications?.length}} </b></div>
      <p-paginator 
    (onPageChange)="paginate($event)" 
    [first]="currentPage"
    [rows]="10" 
    [totalRecords]="totalItems" [pageLinkSize]="10" >
      </p-paginator>
    </div>
  </div>
</div>

<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner></p-progressSpinner>
</div>