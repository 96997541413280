import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { MyQueueComponent } from './components/core/my-queue/my-queue.component';
import { NewRequestQueueComponent } from './components/core/new-request-queue/new-request-queue.component';
import { WorkQueueComponent } from './components/core/work-queue/work-queue.component';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '../shared/shared.module';
import { ApsRoutingModule } from './aps-routing.module';
import { OrderListModule } from 'primeng/orderlist';
import { NgxsModule } from '@ngxs/store';
import { GraphqlModule } from './qraphql/graphql.module';
import { CreateOrderComponent } from './components/core/create-order-components/create-order-wrapper/create-order.component';
import { ApsWrapperComponent } from './components/core/aps-wrapper/aps-wrapper.component';
import { DemographicDetailsComponent } from '../shared/demographic-details/demographic-details.component';
import { RequestLettersComponent } from './components/core/create-order-components/create-lab-order-ae-components/request-letters/request-letters.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SendEmailComponent } from '../shared/send-email/send-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneratePdfComponent } from '../shared/generate-pdf/generate-pdf.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FollowupOrderComponent } from './components/core/create-order-components/create-order-modal-components/followup-order/followup-order.component';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { UshgOrderDetailsComponent } from './components/core/ushg-order-details/ushg-order-details.component';
import { EditOrderComponent } from './components/core/create-order-components/create-order-modal-components/edit-order/edit-order.component';
import { FileUploadModule } from 'primeng/fileupload';
import { EmailSentModalComponent } from './components/core/create-order-components/create-order-modal-components/email-sent-modal/email-sent-modal.component';
import { CreateLabOrderOeComponent } from './components/core/create-order-components/create-lab-order-oe/create-lab-order-oe.component';
import { CreateApsOrderComponent } from './components/core/create-order-components/create-aps-order-components/create-aps-order/create-aps-order.component';
import { CreateApsOrderDemographicComponent } from './components/core/create-order-components/create-aps-order-components/aps-order-demographic-details/create-aps-order-demographic.component';
import { SafePipeModule } from 'safe-pipe';
import { LabOrderAeDemographicComponent } from './components/core/create-order-components/create-lab-order-ae-components/lab-order-ae-demographic/lab-order-ae-demographic.component';
import { OrderCreatedModelComponent } from './components/core/create-order-components/create-lab-order-ae-components/order-created-model/order-created-model.component';
import { RequestCheckModalComponent } from './components/core/create-order-components/create-order-modal-components/request-check-modal/request-check-modal.component';
import { SelectedApplicationContainerComponent } from './components/core/selected-application-container/selected-application-container.component';
import { ApplicationRequestModalComponent } from './components/core/application-request-modal/application-request-modal.component';
import { SidebarModule } from 'primeng/sidebar';
import { RouteToComponent } from './components/core/modals/route-to/route-to.component';
import { FollowupQueueComponent } from './components/core/followup-queue/followup-queue.component';
import { Actions } from '../store/actions';
import { EditOrFollowupOrderComponent } from './components/core/create-order-components/create-order-modal-components/edit-or-followup-order/edit-or-followup-order.component';
import { ListboxModule } from 'primeng/listbox';
import { PaginatorModule } from 'primeng/paginator';
import { AddNotesComponent } from './components/core/modals/add-notes/add-notes.component';
import { ProviderLookupComponent } from './components/core/modals/provider-lookup/provider-lookup.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { ApplicantInfoComponent } from './components/core/selected-application-container/applicant-info/applicant-info.component';
import { ApplicantRequestComponent } from './components/core/selected-application-container/applicant-request/applicant-request.component';
import { ApplicantLogsComponent } from './components/core/selected-application-container/applicant-logs/applicant-logs.component';
import { ApplicantNotesComponent } from './components/core/selected-application-container/applicant-notes/applicant-notes.component';
import { ApplicationFiltersComponent } from '../shared/application-filters/application-filters.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SearchApplicationComponent } from './components/core/search-application/search-application.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastModule } from 'primeng/toast';
import { ToastrService } from '../shared/services/toastr-service';
import { ConfirmModalComponent } from './components/core/modals/confirm-modal/confirm-modal.component';
import { ProgressBarComponent } from './components/core/modals/progress-bar/progress-bar.component';
import { Accordion, AccordionModule } from 'primeng/accordion';
import { UshgApplicationDetailComponent } from './components/core/ushg-application-detail/ushg-application-detail.component';
import { AuthorizationFormsContainerComponent } from './components/core/authorization/authorization-forms-container/authorization-forms-container.component';
import { AuthorizationFormsListComponent } from './components/core/authorization/authorization-forms-list/authorization-forms-list.component';
import { CreateAuthorizationFormComponent } from './components/core/authorization/create-authorization-form/create-authorization-form.component';
import { AuthorizationSearchFormComponent } from './components/core/authorization/authorization-search-form/authorization-search-form.component';
import { TrackApsComponent } from './components/core/track-aps/track-aps.component';
import { TrackApplicationViewComponent } from './components/core/track-application-view/track-application-view.component';

@NgModule({
  declarations: [
    NewRequestQueueComponent,
    MyQueueComponent,
    CreateOrderComponent,
    ApsWrapperComponent,
    DemographicDetailsComponent,
    RequestLettersComponent,
    SendEmailComponent,
    GeneratePdfComponent,
    FollowupOrderComponent,
    EditOrderComponent,
    UshgOrderDetailsComponent,
    EmailSentModalComponent,
    CreateLabOrderOeComponent,
    CreateApsOrderComponent,
    CreateApsOrderDemographicComponent,
    LabOrderAeDemographicComponent,
    OrderCreatedModelComponent,
    RequestCheckModalComponent,
    SelectedApplicationContainerComponent,
    ApplicantInfoComponent,
    ApplicantLogsComponent,
    ApplicantRequestComponent,
    ApplicantNotesComponent,
    ApplicationRequestModalComponent,
    RouteToComponent,
    FollowupQueueComponent,
    EditOrFollowupOrderComponent,
    AddNotesComponent,
    ProviderLookupComponent,
    ApplicationFiltersComponent,
    WorkQueueComponent,
    SearchApplicationComponent,
    ConfirmModalComponent,
    UshgApplicationDetailComponent,
    ProgressBarComponent,
    AuthorizationFormsContainerComponent,
    AuthorizationFormsListComponent,
    CreateAuthorizationFormComponent,
    AuthorizationSearchFormComponent,
    TrackApsComponent,
    TrackApplicationViewComponent
  ],
  imports: [
    SharedModule,
    PaginatorModule,
    MessageModule,
    MessagesModule,
    CommonModule,
    ButtonModule,
    MenubarModule,
    TabMenuModule,
    InputTextModule,
    CalendarModule,
    HttpClientModule,
    CardModule,
    TabViewModule,
    ApsRoutingModule,
    OrderListModule,
    GraphqlModule,
    DropdownModule,
    InputTextareaModule,
    ReactiveFormsModule,
    FormsModule,
    CheckboxModule,
    SafePipeModule,
    SidebarModule,
    RadioButtonModule,
    TableModule,
    FormsModule,
    RadioButtonModule,
    InputTextareaModule,
    ReactiveFormsModule,
    DividerModule,
    FileUploadModule,
    ListboxModule,
    MenuModule,
    NgxsModule.forFeature([Actions]),
    NgSelectModule,
    ProgressSpinnerModule,
    NgxPaginationModule,
    ToastModule,
    AccordionModule,
    PaginatorModule
  ],
  providers: [Accordion],
})
export class ApsModule {}
