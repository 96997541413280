import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { followUp } from 'src/app/aps/queries/query-objects';
import { createFollowupSearchCriteria, createSearchCriteria } from 'src/app/aps/services/graphql-service';
import {
  ApplicationsForFollowUpQueue,
  AssignedApplicationsForLoggedInUser,

  ClearQuery,
  FilterAssignedApplicationsForMyQueue,
} from 'src/app/store/dispatchers';

@Component({
  selector: 'app-aps-wrapper',
  templateUrl: './aps-wrapper.component.html',
  styleUrls: ['./aps-wrapper.component.scss'],
})
export class ApsWrapperComponent {
  navigationHeaderList = [
    {
      label: 'New Request Queue',
      routerLink: ['new-request-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'Work Queue',
      routerLink: ['work-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'My Queue',
      routerLink: ['my-queue'],
      command: () => {
        this.patchMyQueueFilters();
      },
    },
    {
      label: 'Follow-Up Queue',
      routerLink: ['followup-queue'],
      command: () => {
        this.patchFollowupFilters();
      },
    },
    {
      label: 'Search Application',
      routerLink: ['search-application'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'Authorization Forms',
      routerLink: ['authorization-forms'],
    },
    {
      label: 'Track APS',
      routerLink: ['track-aps'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    }


  ];
  constructor(private store: Store) { }

  clearQuery() {
    this.store.dispatch(new ClearQuery());
  }

  patchMyQueueFilters() {
    const filters = this.store.selectSnapshot((state) => state.data.searchFilters);
    if (filters.myQueue && Object.keys(filters.myQueue).length) {
      this.store.dispatch(new FilterAssignedApplicationsForMyQueue(createSearchCriteria(filters.myQueue)))
    } else {
      this.store.dispatch(new ClearQuery());
      this.store.dispatch(new AssignedApplicationsForLoggedInUser());
    }
  }

  patchFollowupFilters() {
    const filters = this.store.selectSnapshot((state) => state.data.searchFilters);
    if (filters.followup && Object.keys(filters.followup).length) {
      const pageNumber = this.store.selectSnapshot((state) => state.data.pageNo);
      const skip = Math.max(0, (pageNumber - 1) * 10);
      const followCopy = {...filters.followup};
      delete followCopy.filters.assignedTo;
      this.store.dispatch(new ApplicationsForFollowUpQueue(createFollowupSearchCriteria(followCopy), skip, 10));
    } else {
      this.store.dispatch(new ClearQuery());
      this.store.dispatch(new ApplicationsForFollowUpQueue('', 0, 10));
    }
  }
}
