import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { isArray } from '@microsoft/applicationinsights-core-js';
import { Store } from '@ngxs/store';
import { SearchFilters } from 'src/app/store/dispatchers';
import {
  CopyServices,
  FollowUpFilter,
  lastNamesFilter,
  orderByFilterWithSorting,
  SearchByTypes,
  StatesFilter,
  StatusFilter,
  timeInQueueFilter,
  typeFilter,
} from '../constants/filter-constants';
import { processStatusColors } from '../constants/process-status-constant';
import { DropDownModel } from '../drop-down/drop-down.component';

export class ApplicationSearchInfo {
  filters?: Filter;
  orderBy?: 'followUpDate' | 'dueDate' | 'reminderDate' | '';
  page?: string;
  state?: string;
  pageDirectionCount?: number;
  skip?: number;
  paginate?: {
    pageInfo?: {
      endCursor?: string;
      hasNextPage?: boolean;
      hasPreviousPage?: boolean;
      startCursor?: string;
    };
    pageCursor?: string;
    pageDirection?: string;
    queryId?: any;
    pageNumber?: number;
  };
  applicationId?: number;
}

interface Filter {
  [key: string]: string | string[] | boolean;
}

interface IApplicationFilterConfiguration {
  defaultSetting?: ApplicationSearchInfo;
  displayStatusDropdown?: boolean;
  displayTypeDropdown?: boolean;
  displayReminderIcon?: boolean;
  displayLastNameDropdown?: boolean;
  displayOrderByDropdown?: boolean;
  displayShowExpiredIcon?: boolean;
  displayStatesDropdown?: boolean;
  displayProcessStatus?: boolean;
  pageName?: String
  displaySearchByTypeDropdown?: boolean;
  displaySearchByTypeRadio?: boolean;
}

export class ApplicationFilterConfiguration {
  constructor(data: IApplicationFilterConfiguration) {
    for (const key in data) {
      this[key] = data[key];
    }
  }
  defaultSetting?: ApplicationSearchInfo;
  displayStatusDropdown = false;
  displayTypeDropdown = false;
  displayReminderIcon = false;
  displayLastNameDropdown = false;
  displayOrderByDropdown = false;
  displayShowExpiredIcon = false;
  displayStatesDropdown = false;
  displayProcessStatus = false;
  pageName = ""
  displaySearchByTypeRadio = false;
  displaySearchByTypeDropdown = false;
}

@Component({
  selector: 'app-application-filters',
  templateUrl: './application-filters.component.html',
  styleUrls: ['./application-filters.component.scss'],
})
export class ApplicationFiltersComponent implements AfterViewInit, AfterViewChecked {
  userProfile = this.store.selectSnapshot((state) => state.data.userProfile);
  processStatusColors = processStatusColors;
  statusColor = '';
  constructor(private store: Store, private cd: ChangeDetectorRef) { }

  @Input() public appFilterConfig: ApplicationFilterConfiguration;
  @Output() public filter = new EventEmitter();
  @Output() public removeFilters = new EventEmitter();
  searchValue = '';
  public type = [];
  public lastName = '';
  public timeInQueue = '';
  public applicationStatusId = '';
  public expired = false;
  public orderBy = '';
  public reminderDate = false;
  public state = [];
  public filterType = ''
  switchToCopyServiceDropDown: boolean;
  searchByType: string;
  copyServiceType: string;
  disableCopyServiceDropdown: boolean;
  public orderByFilter = orderByFilterWithSorting.map((order) => {
    const { name, value } = order;
    return {
      label: name,
      id: value,
      escape: false,
      command: (e) => {
        this.orderBy = e.item.id;
        this.filter.emit(this.applicationFilters);
      },
    };
  });

  public typeFilter: DropDownModel = {
    label: 'All Types',
    placeholder: 'All Types',
    filterValues: typeFilter,
    toolTip: 'All Types',
    onSelect: (data) => {
      data.value = isArray(data.value) && data.value.length > 0 ? data.value : '';
      this.type = this.setValueOrEmptyString(data);
    },
  };

  public filterWithLastName: DropDownModel = {
    label: 'View',
    placeholder: 'All Files',
    filterValues: lastNamesFilter,
    toolTip: 'All Files',
    onSelect: (data) => {
      this.lastName = this.setValueOrEmptyString(data);
    },
  };

  public timeInQueueFilter: DropDownModel = {
    label: 'View',
    placeholder: 'Time in Queue',
    filterValues: timeInQueueFilter,
    onSelect: (data) => {
      this.timeInQueue = this.setValueOrEmptyString(data);
    },
  };

  public statusFilter: DropDownModel = {
    label: 'Type',
    placeholder: 'Type',
    filterValues: StatusFilter,
    onSelect: (data) => {
      this.applicationStatusId = this.setValueOrEmptyString(data);
    },
  };

  public followUpFilter: DropDownModel = {
    label: 'View',
    placeholder: 'Order By',
    filterValues: FollowUpFilter,
    onSelect: (data) => {
      this.timeInQueue = this.setValueOrEmptyString(data);
    },
  };

  public stateFilter: DropDownModel = {
    label: 'Type',
    placeholder: 'All States',
    filterValues: StatesFilter,
    toolTip: 'All States',
    onSelect: (data) => {
      data.value = isArray(data.value) && data.value.length > 0 ? data.value : '';
      this.state = this.setValueOrEmptyString(data);
    },
  };

  public get applicationFilters() {
    return {
      filters: {
        lastName: this.lastName,
        timeInQueue: this.timeInQueue,
        type: this.type,
        expired: this.expired,
        searchValue: this.searchValue,
        reminderDate: this.reminderDate,
        applicationStatusId: this.applicationStatusId,
        state: this.state,
        assignedTo: this.userProfile.domainUserName,
        filterType: this.filterType,
        processStatus: this.statusColor
      },
      orderBy: this.orderBy,
      paginate: {
        pageDirection: null,
        queryId: null,
        pageNumber: null,
        pageInfo: {
          startCursor: null,
          endCursor: null
        }
      },
    } as ApplicationSearchInfo;
  }

  public ngAfterViewInit(): void {
    const defaultSetting = this.appFilterConfig.defaultSetting;
    for (const key in defaultSetting) {
      if (typeof defaultSetting[key] === 'string') {
        this[key] = defaultSetting[key];
      } else {
        for (const key2 in defaultSetting[key]) {
          this[key2] = defaultSetting[key][key2];
          if (key2 === 'filterType') {
            this.searchByType = defaultSetting[key][key2];
          }
          if (key2 === 'processStatus') {
            this.statusColor = defaultSetting[key][key2]
          }
        }
      }
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  canDisableClearButton() {
    return (this.searchValue || this.applicationStatusId ||
      this.state || this.type || this.lastName || this.orderBy ||
      this.reminderDate || this.expired || this.statusColor) ? false : true;
  }

  canDisableSearchButton() {
    return (this.searchValue || this.applicationStatusId ||
      this.state || this.type || this.lastName || this.statusColor) ? false : true;
  }

  public search(): void {
    if (this.filter) {
      this.filter.emit(this.applicationFilters);
    }
    this.setSearchFilters();

  }

  public searchApplications(event) {
    if (event === 'EnterPressed') {
      this.search();
    } else {
      if (event.length) {
        this.searchValue = event;
      }
      else if (!event.length) {
        this.clearFilters();
      }
    }

    // this.setSearchFilters();
  }

  public showExpiredApplications() {
    this.expired = !this.expired;
    this.filter.emit(this.applicationFilters);
    this.setSearchFilters();
  }

  clearFiltersData() {
    if (this.filterType === 'copyService') {
      this.disableCopyServiceDropdown = true;
    }
    this.type = this.setValueOrEmptyString('');
    this.state = this.setValueOrEmptyString('');
    this.lastName = '';
    this.orderBy = '';
    this.searchValue = '';
    this.applicationStatusId = '';
    this.reminderDate = false;
    this.expired = false;
    this.filterType = null;
    this.statusColor = '';
    this.searchByType = ''
    this.copyServiceType = ''
  }
  
  public clearFilters() {
    this.clearFiltersData()
    this.removeFilters.emit(this.applicationFilters);
  }

  public showReminderDate() {
    this.reminderDate = !this.reminderDate;
    this.orderBy = 'expiredApplications';
    this.filter.emit(this.applicationFilters);
    this.setSearchFilters();
  }

  private setValueOrEmptyString(data) {
    return data?.value ? data.value : '';
  }

  public setSearchFilters() {
    var pageName = this.appFilterConfig.pageName
    const filters = Object.create({});
    filters[pageName] = this.applicationFilters;
    this.store.dispatch(new SearchFilters(filters))
  }

  get orderByStatus() {
    return this.orderByFilter.some((filter) => filter.id === this.orderBy);
  }

  updateStatusColor(event) {
    this.statusColor = event;
  }

  getStatusColor() {
    return this.processStatusColors.filter(
      (e) => e.key == this.statusColor
    )[0]?.value;
  }

  public SearchByTypes: DropDownModel = {
    label: '',
    placeholder: 'Search By',
    filterValues: SearchByTypes,
    onSelect: (data) => {
      this.searchByType = this.setValueOrEmptyString(data);
      this.switchToCopyServiceDropDown = false;
      this.filterType = data.value;
      if (this.filterType === 'copyService') {
        this.switchToCopyServiceDropDown = true;
        this.disableCopyServiceDropdown = false;
      }
    },
  };

  public CopyServices: DropDownModel = {
    label: '',
    placeholder: 'Seach Copy service',
    filterValues: CopyServices,
    onSelect: (data) => {
      this.copyServiceType = this.setValueOrEmptyString(data);
    },
  };

}
