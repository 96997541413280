import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-track-application-view',
  templateUrl: './track-application-view.component.html',
  styleUrls: ['./track-application-view.component.scss']
})
export class TrackApplicationViewComponent implements OnInit {
  config = {
    width: '62%',
    height: '75%',
    closable:true
   }
  modeType = "custom";
  selectedApplication: any;
  buttonsList = [
    {
      label: 'Close',
      type: 'secondaryoutline',

      onClick: () => {
        this.dialogService.cancel();
      }
    }
  ];
  constructor(
    private dialog: DynamicDialogConfig,
    private dialogService: UshgDialogService,
    private apsApiService: ApsApiService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.apsApiService.trackApplicationView(this.dialog.data.requestID).subscribe((res) => {
      this.selectedApplication = res;
      this.selectedApplication['name'] = res.firstName + ' ' + res.lastName;
      this.selectedApplication['dob'] = this.datePipe.transform(res.dob, 'MM/dd/yyyy');
      this.selectedApplication.contacts[0].contactTime = this.datePipe.transform(res.contacts[0].contactTime, 'MM/dd/yyyy');
    });
  }

}
