import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';
import { LogoutComponent } from './shared/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./aps/aps.module').then((m) => m.ApsModule),
   
  },
  {
    path: 'aps',
    loadChildren: () => import('./aps/aps.module').then((m) => m.ApsModule)
  },
  {
    path: 'nbc',
    loadChildren: () => import('./nbc/nbc.module').then((m) => m.NbcModule)
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
